import React from 'react';

const About = () => {

    const progressInWeb = {
        width: '85%',
        height: '100%',
        position: 'absolute',
        left: 0,
        top: 0,
        borderRadius: '5px',
        backgroundColor: 'var(--skin-color)'
      };
      const progressInMobile = {
        width: '75%',
        height: '100%',
        position: 'absolute',
        left: 0,
        top: 0,
        borderRadius: '5px',
        backgroundColor: 'var(--skin-color)'
      };
      const progressInBackend = {
        width: '65%',
        height: '100%',
        position: 'absolute',
        left: 0,
        top: 0,
        borderRadius: '5px',
        backgroundColor: 'var(--skin-color)'
      };


    return (
        <section id='about' className="section about">
            <div className="container">
                <div className="row">
                    <div className="section-title p-15">
                        <h2>About Me</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="about-content p-15">
                        <div className="row">
                            <div className="about-text p-15">
                                <h3>I'm Lycoris Blue and <span>Web Developer</span></h3>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio est nesciunt quaerat provident porro repellat ut, officiis, aut iure reprehenderit numquam. Eius ipsa inventore, voluptate sapiente dolorum eum voluptatem blanditiis fuga illum laborum accusantium officiis veritatis dolorem, dicta, in maiores. Ea neque optio fuga suscipit, omnis maxime facilis corrupti id doloribus repellendus ipsum quam voluptatum!</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="personal-info p-15">
                                <div className="row">
                                    <div className="info-item">
                                        <p>Birthday : <span>18 march 2005</span></p>
                                    </div>
                                    <div className="info-item">
                                        <p>Age : <span>18</span></p>
                                    </div>
                                    <div className="info-item">
                                        <p>Website : <span>lycoris.org</span></p>
                                    </div>
                                    <div className="info-item">
                                        <p>Email : <span>lycorisblue@gmail.com</span></p>
                                    </div>
                                    <div className="info-item">
                                        <p>Country : <span>Ivory coast</span></p>
                                    </div>
                                    <div className="info-item">
                                        <p>City : <span>Abidjan</span></p>
                                    </div>
                                    <div className="info-item">
                                        <p>Phone : <span>+255 05 86 99 01 54</span></p>
                                    </div>
                                    <div className="info-item">
                                        <p>Sexe : <span>M</span></p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="buttons p-15">
                                        <a href="#" className='btn btn-2'>Download CV</a>
                                        <a href="#contact" className='btn hire-me'>Hire me</a>
                                    </div>
                                </div>
                            </div>
                            <div className="skills p-15">
                                <div className="skill-item">
                                    <h5>MOBILE</h5>
                                    <div className="progress">
                                        <div className="progress-in" style={progressInMobile}></div>
                                        <div className="skill-percent">75%</div>
                                    </div>
                                </div>
                                <div className="skill-item">
                                    <h5>WEB</h5>
                                    <div className="progress">
                                        <div className="progress-in" style={progressInWeb}></div>
                                        <div className="skill-percent">85%</div>
                                    </div>
                                </div>
                                <div className="skill-item">
                                    <h5>BACKEND</h5>
                                    <div className="progress">
                                        <div className="progress-in" style={progressInBackend}></div>
                                        <div className="skill-percent">65%</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="education p-15">
                                <h3 className="title">Education</h3>
                                <div className="row">
                                    <div className="timeline-box p-15">
                                        <div className="timeline dark-shadow">
                                            {/*============= timeline-items =============*/}
                                            <div className="timeline-item">
                                                <div className="circle-dot"></div>
                                                <h3 className="timeline-date">
                                                    <i className="fa fa-calendar"></i> 0000 - 0000
                                                </h3>
                                                <h4 className="timeline-title">Master in Computer Science</h4>
                                                <p className="timeline-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptates architecto sapiente distinctio non obcaecati iste doloribus sit, reiciendis minus blanditiis exercitationem voluptas consectetur eligendi sunt!</p>
                                            </div>
                                            <div className="timeline-item">
                                                <div className="circle-dot"></div>
                                                <h3 className="timeline-date">
                                                    <i className="fa fa-calendar"></i> 0000 - 0000
                                                </h3>
                                                <h4 className="timeline-title">Master in Computer Science</h4>
                                                <p className="timeline-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptates architecto sapiente distinctio non obcaecati iste doloribus sit, reiciendis minus blanditiis exercitationem voluptas consectetur eligendi sunt!</p>
                                            </div>
                                            <div className="timeline-item">
                                                <div className="circle-dot"></div>
                                                <h3 className="timeline-date">
                                                    <i className="fa fa-calendar"></i> 0000 - 0000
                                                </h3>
                                                <h4 className="timeline-title">Master in Computer Science</h4>
                                                <p className="timeline-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptates architecto sapiente distinctio non obcaecati iste doloribus sit, reiciendis minus blanditiis exercitationem voluptas consectetur eligendi sunt!</p>
                                            </div>
                                            <div className="timeline-item">
                                                <div className="circle-dot"></div>
                                                <h3 className="timeline-date">
                                                    <i className="fa fa-calendar"></i> 0000 - 0000
                                                </h3>
                                                <h4 className="timeline-title">Master in Computer Science</h4>
                                                <p className="timeline-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptates architecto sapiente distinctio non obcaecati iste doloribus sit, reiciendis minus blanditiis exercitationem voluptas consectetur eligendi sunt!</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="experience p-15">
                                <h3 className="title">Experience</h3>
                                <div className="row">
                                    <div className="timeline-box p-15">
                                        <div className="timeline dark-shadow">
                                            {/*============= timeline-items =============*/}
                                            <div className="timeline-item">
                                                <div className="circle-dot"></div>
                                                <h3 className="timeline-date">
                                                    <i className="fa fa-calendar"></i> 0000 - 0000
                                                </h3>
                                                <h4 className="timeline-title">Master in Computer Science</h4>
                                                <p className="timeline-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptates architecto sapiente distinctio non obcaecati iste doloribus sit, reiciendis minus blanditiis exercitationem voluptas consectetur eligendi sunt!</p>
                                            </div>
                                            <div className="timeline-item">
                                                <div className="circle-dot"></div>
                                                <h3 className="timeline-date">
                                                    <i className="fa fa-calendar"></i> 0000 - 0000
                                                </h3>
                                                <h4 className="timeline-title">Master in Computer Science</h4>
                                                <p className="timeline-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptates architecto sapiente distinctio non obcaecati iste doloribus sit, reiciendis minus blanditiis exercitationem voluptas consectetur eligendi sunt!</p>
                                            </div>
                                            <div className="timeline-item">
                                                <div className="circle-dot"></div>
                                                <h3 className="timeline-date">
                                                    <i className="fa fa-calendar"></i> 0000 - 0000
                                                </h3>
                                                <h4 className="timeline-title">Master in Computer Science</h4>
                                                <p className="timeline-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptates architecto sapiente distinctio non obcaecati iste doloribus sit, reiciendis minus blanditiis exercitationem voluptas consectetur eligendi sunt!</p>
                                            </div>
                                            <div className="timeline-item">
                                                <div className="circle-dot"></div>
                                                <h3 className="timeline-date">
                                                    <i className="fa fa-calendar"></i> 0000 - 0000
                                                </h3>
                                                <h4 className="timeline-title">Master in Computer Science</h4>
                                                <p className="timeline-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptates architecto sapiente distinctio non obcaecati iste doloribus sit, reiciendis minus blanditiis exercitationem voluptas consectetur eligendi sunt!</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;