import React from 'react';

const Aside = () => {
    return (
        <div className='aside'>
            <div className="logo">
                <a href="#"><span>L</span>ycoris</a>
            </div>
            <div className="toggle">
                <span></span>
            </div>
            <ul className="nav">
                <li><a href="#home" className='active'><i className="fa fa-home"></i> Home</a></li>
                <li><a href="#about"><i className="fa fa-user"></i> About</a></li>
                <li><a href="#services"><i className="fa fa-list"></i> Services</a></li>
                <li><a href="#portfolio"><i className="fa fa-briefcase"></i> Portfolio</a></li>
                <li><a href="#contact"><i className="fa fa-comments"></i> Contact</a></li>
            </ul>
        </div>
    );
}

export default Aside;
