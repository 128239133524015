import './App.css';
import Aside from './components/Aside';
import About from './components/pages/About';
import Contact from './components/pages/Contact';
import Home from './components/pages/Home';
import Portfolio from './components/pages/Portfolio';
import Services from './components/pages/Services';

function App() {
  return (
    <>
      <Aside/>
      <div className="main-content">
        <Home/>
        <About/>
        <Services/>
        <Portfolio/>
        <Contact/>
      </div>
    </>
  );
}

export default App;
