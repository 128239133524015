import React from 'react';

const Services = () => {
    return (
        <section id='services' className='service section'>
            <div className="container">
                <div className="row">
                    <div className="section-title p-15">
                        <h2>Services</h2>
                    </div>
                </div>
                <div className="row">
                    {/* ===== Services item Start ===== */}
                    <div className="service-item p-15">
                        <div className="service-item-inner">
                            <div className="icon">
                                <i className="fa fa-mobile-alt"></i>
                            </div>
                            <h4>Web Design</h4>
                            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Inventore error officia nostrum.</p>
                        </div>
                    </div>
                    {/* ===== Services item Start ===== */}
                    {/* ===== Services item Start ===== */}
                    <div className="service-item p-15">
                        <div className="service-item-inner">
                            <div className="icon">
                                <i className="fa fa-laptop-code"></i>
                            </div>
                            <h4>Web Design</h4>
                            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Inventore error officia nostrum.</p>
                        </div>
                    </div>
                    {/* ===== Services item Start ===== */}
                    {/* ===== Services item Start ===== */}
                    <div className="service-item p-15">
                        <div className="service-item-inner">
                            <div className="icon">
                                <i className="fa fa-palette"></i>
                            </div>
                            <h4>Web Design</h4>
                            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Inventore error officia nostrum.</p>
                        </div>
                    </div>
                    {/* ===== Services item Start ===== */}
                    {/* ===== Services item Start ===== */}
                    <div className="service-item p-15">
                        <div className="service-item-inner">
                            <div className="icon">
                                <i className="fa fa-code"></i>
                            </div>
                            <h4>Web Design</h4>
                            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Inventore error officia nostrum.</p>
                        </div>
                    </div>
                    {/* ===== Services item Start ===== */}
                    {/* ===== Services item Start ===== */}
                    <div className="service-item p-15">
                        <div className="service-item-inner">
                            <div className="icon">
                                <i className="fa fa-search"></i>
                            </div>
                            <h4>Web Design</h4>
                            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Inventore error officia nostrum.</p>
                        </div>
                    </div>
                    {/* ===== Services item Start ===== */}
                    {/* ===== Services item Start ===== */}
                    <div className="service-item p-15">
                        <div className="service-item-inner">
                            <div className="icon">
                                <i className="fa fa-bullhorn "></i>
                            </div>
                            <h4>Web Design</h4>
                            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Inventore error officia nostrum.</p>
                        </div>
                    </div>
                    {/* ===== Services item Start ===== */}
                </div>
            </div>
        </section>
    );
}

export default Services;
