import React from 'react';

const Contact = () => {
    return (
        <section id='contact' className='section contact'>
            <div className="container">
                <div className="row">
                    <div className="section-title p-15">
                        <h2>Contact me</h2>
                    </div>
                </div>
                <h3 className='contact-title p-15'>Have You Any Questions ?</h3>
                <h4 className='contact-sub-title p-15'>I'm at Your services</h4>
                <div className="row">
                    {/* Contact infos item start */}
                    <div className="contact-info-item p-15">
                        <div className="icon"><i className="fa fa-phone"></i></div>
                        <h4>Call us on</h4>
                        <p>+225 0575635710</p>
                    </div>
                    {/* Contact infos item start */}
                    {/* Contact infos item start */}
                    <div className="contact-info-item p-15">
                        <div className="icon"><i className="fa fa-map-marker-alt"></i></div>
                        <h4>Office</h4>
                        <p>Abidjan</p>
                    </div>
                    {/* Contact infos item start */}
                    {/* Contact infos item start */}
                    <div className="contact-info-item p-15">
                        <div className="icon"><i className="fa fa-envelope"></i></div>
                        <h4>Email</h4>
                        <p>lycorisblue99@gmail.com</p>
                    </div>
                    {/* Contact infos item start */}
                    {/* Contact infos item start */}
                    <div className="contact-info-item p-15">
                        <div className="icon"><i className="fa fa-globe-europe"></i></div>
                        <h4>Website</h4>
                        <p>www.arkx.com</p>
                    </div>
                    {/* Contact infos item start */}
                </div>
                <h3 className='contact-title p-15'>SEND ME EMAIL</h3>
                <h4 className='contact-sub-title p-15'>I'm very resposive to messages</h4>
                <div className="row">
                    <div className="contact-form p-15">
                        <div className="row">
                            <div className="form-item col-6 p-15">
                                <div className="form-group">
                                    <input type="text" className='form-control' placeholder='Name'/>
                                </div>
                            </div>
                            <div className="form-item col-6 p-15">
                                <div className="form-group">
                                    <input type="email" className='form-control' placeholder='Email'/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-item col-12 p-15">
                                <div className="form-group">
                                    <input type="text" className='form-control' placeholder='Subject'/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-item col-12 p-15">
                                <div className="form-group">
                                    <textarea name="" id="" className="form-control" placeholder='Message'></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="row p-15">
                            <button type='submit' className='btn'>Send Me</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Contact;
