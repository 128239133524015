import React from 'react';
import img1 from '../../img/676967.jpg'

const Home = () => {
    return (
        <section id='home' className='home section'>
            <div className="container">
                <div className="row">
                    <div className="home-infos p-15">
                        <h3 className="hello">Hello, My name is <span className="name">Lycoris Blue</span></h3>
                        <h3 className="my-profession">I'm a <span className='typing'>Developper Mobile</span></h3>
                        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Architecto recusandae iste, maxime voluptatibus reiciendis officia doloribus, est, assumenda molestias dignissimos </p>
                        <a href="#contact" className="btn hire-me">Hire Me</a>
                    </div>
                    <div className="home-img p-15">
                        <img src={img1} alt="" />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Home;
