import React from 'react';

const Portfolio = () => {
    return (
        <section id='portfolio' className="portfolio section">
            <div className="container">
                <div className="row">
                    <div className="section-title p-15">
                        <h2>Portfolio</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="portfolio-heading p-15">
                        <h2>My Last Projects : </h2>
                    </div>
                </div>
                <div className="row">
                    {/* ===== portfolio item start ===== */}
                    <div className="portfolio-item p-15">
                        <div className="portfolio-item-inner shadow-dark">
                            <div className="portfolio-img">
                                <img src="https://i.pinimg.com/736x/0a/fb/9a/0afb9a4444a3c8100bc60df609a4feab.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                    {/* ===== portfolio item end ===== */}
                    {/* ===== portfolio item start ===== */}
                    <div className="portfolio-item p-15">
                        <div className="portfolio-item-inner shadow-dark">
                            <div className="portfolio-img">
                                <img src="https://i.pinimg.com/736x/0a/fb/9a/0afb9a4444a3c8100bc60df609a4feab.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                    {/* ===== portfolio item end ===== */}
                    {/* ===== portfolio item start ===== */}
                    <div className="portfolio-item p-15">
                        <div className="portfolio-item-inner shadow-dark">
                            <div className="portfolio-img">
                                <img src="https://i.pinimg.com/736x/0a/fb/9a/0afb9a4444a3c8100bc60df609a4feab.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                    {/* ===== portfolio item end ===== */}
                    {/* ===== portfolio item start ===== */}
                    <div className="portfolio-item p-15">
                        <div className="portfolio-item-inner shadow-dark">
                            <div className="portfolio-img">
                                <img src="https://i.pinimg.com/736x/0a/fb/9a/0afb9a4444a3c8100bc60df609a4feab.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                    {/* ===== portfolio item end ===== */}
                    {/* ===== portfolio item start ===== */}
                    <div className="portfolio-item p-15">
                        <div className="portfolio-item-inner shadow-dark">
                            <div className="portfolio-img">
                                <img src="https://i.pinimg.com/736x/0a/fb/9a/0afb9a4444a3c8100bc60df609a4feab.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                    {/* ===== portfolio item end ===== */}
                    {/* ===== portfolio item start ===== */}
                    <div className="portfolio-item p-15">
                        <div className="portfolio-item-inner shadow-dark">
                            <div className="portfolio-img">
                                <img src="https://i.pinimg.com/736x/0a/fb/9a/0afb9a4444a3c8100bc60df609a4feab.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                    {/* ===== portfolio item end ===== */}
                </div>
            </div>
        </section>
    );
}

export default Portfolio;
